/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about-background {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.about-container {
  margin-top: 38px;
  background-color: #ffffff;
  max-width: 800px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  color: #333333;
}

/* Heading Styles */
.about-heading {
  margin-top:50px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333333;
  border-bottom: 2px solid  #0066cc;
  padding-bottom: 10px;
}

/* Section Titles */
.about-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0066cc;
}

/* Content Styles */
.about-content,
.content-experience,
.content {
  font-size: 1rem;
  line-height: 1.8;
  color: #555555;
  margin-bottom: 20px;
}

.name {
  font-weight: bold;
  color: #222222;
}

/* Button Styles */
#about-button {
  background-color: #007BFF; /* Golden accent */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: block;
  margin: 0 auto;
}

#about-button:hover {
  background-color: #0056B3; /* Slightly darker gold on hover */
  transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
    padding: 20px;
  }

  .about-heading {
    font-size: 1.8rem;
    margin-top: 60px;
  }

  .about-title {
    font-size: 1.3rem;
  }

  #estimate-button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .about-heading {
    font-size: 1.5rem;
    margin-top: 70px;
  }

  .about-title {
    font-size: 1.2rem;
  }

  .about-content,
  .content-experience,
  .content {
    font-size: 0.9rem;
  }
}
