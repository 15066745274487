.bedroom-background {
  background-image: url('../../public/bedroom.jpg');
  background-position: center;
  background-size: cover; 
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white;
  text-shadow: 1px 1px 2px black;
  position: relative; 
  opacity: 97%;
  padding:30px;
}
.bedroom-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15); 
  z-index: 1;
  pointer-events: none;
}

.wall-section, .slidingdoor-section, .curtians-section, .doorhandle-section, .fan-section, .furnitureAssemble-section {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: left 0.3s ease; 
}

.button-style {
  background-color: rgba(240, 241, 217, 0.1);
  color: white; 
  padding: 15px 32px; 
  font-size: 16px; 
  border: none; 
  cursor: pointer; 
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin: 20px;
}

.wall-section{
  position: absolute;
  top: 35%; 
  left: 77%; 
}
.slidingdoor-section{
  position: absolute;
  top: 31%; 
  left: 55%; 
}
.curtians-section{
  position: absolute;
  top: 26%; 
  left: 1%; 
}
.doorhandle-section{
  position: absolute;
  top: 54%; 
  left: 70%; 
}
.fan-section{
  position: absolute;
  top: 15%; 
  left: 48%; 
}
.furnitureAssemble-section{
  position: absolute;
  top: 70%; 
  left: 30%; 
}

@media (max-width: 800px) {
  .wall-section,
  .slidingdoor-section,
  .curtians-section,
  .doorhandle-section,
  .fan-section,
  .furnitureAssemble-section
  {
    left: calc(100vw - 200px); 
    margin-left: 20px; 
  }

  .fan-section{
    top:25%;
  }
  .wall-section{
    top:35%;
  }
  .curtians-section{
    top:45%;
  }
  .doorhandle-section{
    top:55%;
  }
  .slidingdoor-section{
    top:65%;
  }
  .furnitureAssemble-section{
    top:75%;
  }
}
