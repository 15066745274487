.outdoor-background {
  background-image: url('../../public/house.jpg');
  background-position: center;
  background-size: cover; 
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white;
  position: relative; 
  text-shadow: 1px 1px 2px black;
  opacity: 97%;
  padding-left: 30px;
}

.outdoor-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); 
  z-index: 1;
  pointer-events: none;
}

.roof-section,
.interlock-section,
.driveway-section,
.bollards-section,
.window-section,
.door-section,
.gate-section {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: left 0.3s ease;
}


.roof-section{
  position: absolute;
  top: 20%; 
  left: 36%;
}

.interlock-section{
  position: absolute;
  top: 82%; 
  left: 40%;
}

.driveway-section{
  position: absolute;
  top: 82%; 
  left: 80%;
}

.bollards-section{
  position: absolute;
  top: 82%; 
  left: 60%;
}

.window-section{
  position: absolute;
  top: 40%; 
  left: 70%;
}

.outdoor-background .door-section{
  position: absolute;
  top: 60%; 
  left: 66%;
}

.gate-section{
  position: absolute;
  top: 82%; 
  left: 25%;
}

.siding-section{
  position: absolute;
  top: 40%; 
  left: 38%;
}


@media (max-width: 800px) {
  .roof-section,
  .interlock-section,
  .driveway-section,
  .bollards-section,
  .window-section,
  .outdoor-background .door-section,
  .gate-section,
  .siding-section
  {
    left: calc(100vw - 200px); 
    margin-left: 20px; 
  }

  .roof-section{
    top:15%;
  }
  .interlock-section{
    top:25%;
  }
  .driveway-section{
    top:35%;
  }
  .bollards-section{
    top:45%;
  }
  .window-section{
    top:55%;
  }
  .outdoor-background .door-section{
    top:65%;
  }
  .gate-section{
    top:75%;
  }
  .siding-section{
    top:85%;
  }
}

header, .message {
  display: flex;
  justify-content: flex-start; /* Align content to the left */
  align-items: center;
  text-align: left;
  position: relative;
  z-index: 2;
}

