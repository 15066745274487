.App {
  text-align: center;
   background-color: #BBB3A4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-button {
  background-color: rgba(240, 241, 217, 0.2);
  color: white; 
  padding: 15px 35px; 
  font-size: 18px; 
  border: none; 
  cursor: pointer; 
  border-radius: 20px;
  margin: 20px;
}

.dropdown-button:hover {
  background-color: #333333; 
  color: white;
}

.App .dropdown {
  position: relative;
  display: inline-block;
}

.App .dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  min-width: 160px;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color:#333; 
  left: -160px;
  border-radius: 20px;
  text-shadow: none;
}

.App .dropdown:hover .dropdown-content {
  display: block;
}

.App .dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: white;
}

.App .dropdown-content ul li {
  padding: 8px 0; 
  color: white;
}

@media (max-width: 800px) {
  .wall-section,
  .slidingdoor-section,
  .curtians-section,
  .doorhandle-section,
  .fan-section,
  .furnitureAssemble-section,
  .roof-section,
  .interlock-section,
  .driveway-section,
  .bollards-section,
  .window-section,
  .outdoor-background .door-section,
  .gate-section,
  .siding-section,
  .showerhead-section,
  .tileitems-section, 
  .sink-section,
  .backsplash-section,
  .faucet-section,
  .kitchen-background .door-section {
    right: 0; 
    margin-right: 35px; 
  }
  .roof-section button,
  .interlock-section button,
  .driveway-section button,
  .bollards-section button,
  .window-section button,
  .outdoor-background .door-section button,
  .gate-section button,
  .siding-section button,
  .showerhead-section button,
  .tileitems-section button,
  .wall-section button,
  .slidingdoor-section button,
  .curtians-section button,
  .doorhandle-section button,
  .fan-section button,
  .furnitureAssemble-section button,
  .sink-section button,
  .backsplash-section button,
  .faucet-section button,
  .door-section button {
    background-color: #333; 
    color: #fff; 
    white-space: nowrap; /* Ensures the text stays on one line */
    padding: 10px 20px; /* Adjust padding */
    font-size: 18px; 
  }

  .page-header {
    font-size: 40px !important;
  }
  
  .message {
    font-size: 15px !important;
  }
}


@media (min-width: 800px) {
  .App .dropdown-content {
    left: auto; /* Reset left */
    right: -160px; /* Align to the right of the button */
    top: 0;
  }
}

.page-header {
  font-size: 60px;
}

.message {
  font-size: 35px;
}
