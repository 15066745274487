.kitchen-background {
  background-image: url("../../public/kitchen.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  position: relative;
  text-shadow: 1px 1px 2px black;
  opacity: 97%;
  padding: 30px;
}

.kitchen-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  pointer-events: none;
}

.sink-section,
.backsplash-section,
.faucet-section,
.door-section {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: left 0.3s ease;
}

.sink-section {
  position: absolute;
  top: 63%;
  left: 47%;
}

.backsplash-section {
  position: absolute;
  top: 38%;
  left: 49%;
}

.faucet-section {
  position: absolute;
  top: 55%;
  left: 57%;
  z-index: 4;
}

.kitchen-background .door-section {
  position: absolute;
  top: 25%;
  left: 7%;
}

@media (max-width: 800px) {
  .sink-section,
  .backsplash-section,
  .faucet-section,
  .kitchen-background .door-section {
    left: calc(100vw - 200px);
    margin-left: 20px;
  }
  .kitchen-background .door-section {
    top: 55%;
  }
  .faucet-section {
    top: 35%;
  }
  .backsplash-section {
    top: 65%;
  }
  .sink-section {
    top: 45%;
  }
  .sink-section button,
  .backsplash-section button,
  .faucet-section button,
  .door-section button {
    background-color: #333;
    color: #fff;
  }
}
