.home-background {
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center; /* Initially center for smaller screens */
  align-items: center;
  padding: 2rem;
  font-size: 1rem;
  position: relative;
  z-index: 100;
  text-align: center;
  color: white;
  height: 100vh;
}

.home-container{
  font-size: 1.2rem;
  line-height: 1.6;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  max-width: 900px;
  text-align: center;
  transition: transform 0.3s ease;
  margin-top: 15px;
}

.main-title {
  margin-top: 6rem;
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

#estimate-button{
  background-color: #f0f1d91a;
  border: none;
  border-radius: 20px; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  color: white; 
  cursor: pointer;
  font-size: 16px;  
  margin: 20px 0;
  padding: 15px 32px; 
}

#estimate-button:hover {
  background-color: #333333; 
}

.description {
  border-radius: 10px;
  text-shadow: none;
}
.home-title {
  font-weight: bold;
  font-size: 1.5rem;
}
.home-content {
  font-size: 1.2rem;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  max-width: 100%; /* Responsive width */
  transition: transform 0.3s ease;
}
/* Responsive Design */
/*PERFECT FOR LAPTOP*/
@media (max-width: 768px) {
  .home-background {
  height: 100vh;
  }
  .home-container{
    width: 90%;
  }

  .main-tittle  {
    margin-top: 8rem;
    font-weight: bold;
    font-size: 2.5rem;
  }

  #estimate-button {
    padding: 12px 24px; 
    font-size: 14px;
  }

  .description {
    padding: 15px;
  }

  .home-title {
    font-size: 1.2rem;
  }

  .home-content {
    font-size: 1.2rem;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    max-width: 100%; /* Responsive width */
    transition: transform 0.3s ease;
  }
}
@media (max-width: 450px) {
  .home-background {
  height: 115vh;
  }
  .home-container{
    width: 90%;
  }

  .main-tittle  {
    margin-top: 6rem;
    font-weight: bold;
    font-size: 2.5rem;
  }

  #estimate-button {
    padding: 12px 24px; 
    font-size: 14px;
  }

  .home-title {
    font-size: 1.2rem;
  }

  .home-content {
    font-size: 1.2rem;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    max-width: 100%; /* Responsive width */
    transition: transform 0.3s ease;
  }
}