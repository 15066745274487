.contact-background {
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center; /* Initially center for smaller screens */
  align-items: center;
  padding: 2rem;
  font-size: 1rem;
  position: relative;
  z-index: 100;
  text-align: center;
  height: 100vh;
  }

/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact-background {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.contact-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  padding: 30px;
  text-align: center;
  margin-top: 30px;
}

/* Header and error message */
.contact-page-header {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333333;
}

.error-message {
  font-size: 1rem;
  color: #ff6b6b;
  margin-bottom: 20px;
}

.contact-contact-info p {
  font-size: 1rem;
  margin: 5px 0;
}
.c-email-link,
.c-phone-link {
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

.c-email-link:hover,
.c-phone-link:hover {
  text-decoration: underline;
}

/* Form styles */
form {
  margin-top: 20px;
}

form div {
  margin-bottom: 15px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 1rem;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #0066cc;
}

textarea {
  height: 100px;
  resize: none;
}

/* Error text */
.error-text {
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

/* Submit button */
button {
  background-color: #0066cc;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #004d99;
}

/* Responsive design */
@media (max-width: 480px) {
  .contact-container {
    padding: 20px;
    margin-top: 40px;
  }

  .contact-page-header {
    font-size: 1.5rem;
  }

  input,
  textarea {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}
