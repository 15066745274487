.form-container {
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
  }
  
  .form-container h2 {
    margin-top: 0;
  }
  
  .form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .form-container button {
    margin-top: 10px;
  }
  