.logo {
  height: 90px;
}

/* Basic styles for the navbar */
.navbar{
height: 90px;
align-items: center;
background-color: #d2c9b9;
box-shadow: 0 4px 12px #0000001a;
box-sizing: border-box;
display: flex;
font-size: 26px;
gap: 20px;
justify-content: center;
list-style-type: none;
padding: 1px;
position: fixed;
top: 24px;
width: 100%;
z-index: 999;
}

/* Hover effect for nav links */
.nav-links a:hover {
  color:#1D73C1;
}

/* Nav links styles */
.nav-links {
  list-style: none;
  display: flex;
  margin: 1px;
  padding: 0;
  justify-content: center;
  align-items: center;
}

/* Individual nav link styles */
.nav-links li a {
  display: block;
  color: white;
  text-decoration: none;
  padding: 10px;
}
/* Dropdown styles specific to navbar */
.navbar .dropdown {
  position: relative;
  display: inline-block;
}

.navbar .dropdown-content {
  display: none;
  position: absolute;
  background-color: #d2c9b9;
  z-index: 1;
  border-radius: 4px;
  top: 100%; /* Dropdown appears below the button */
  left: 0;
  border-radius: 20px;
}
.navbar .dropdown:hover .dropdown-content {
    display: block;
  }

.navbar-dropdown .dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dropdown-content li {
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  color: #333333;
}
.dropdown-content li:hover {
  background-color: #ddd;
}

/* Responsive design: Adjust font sizes and layout for smaller screens */
@media (max-width: 1024px) {
  .navbar {
    font-size: 25px;
    padding: 5px;
    top: 20px;
  }

  .nav-links {
    gap: 9px;
  }

  .nav-links li a {
    padding: 7px;
  }
  .logo {
    height: 85px;
  }
}

@media (max-width: 768px) {
  .navbar {
    font-size: 20px;
    padding: 1px;
    top: 20px;
  }

  .nav-links {
    gap: 2px;
  }

  .nav-links li a {
    padding: 6px;
  }
}

@media (max-width: 430px) {
  .navbar {
    font-size: 18px;
    top: 20px;
  }

  .nav-links {
    gap: 2px;
  }

  .nav-links li a {
    padding: 6px;
  }
  .logo {
    height: 40px;
  }
}
