*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.footer-background {
  background-position: center;
  background-size: cover; 
  height: 40vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.footer-section {
flex: 1;
margin: 10px;
}

.footer-section h2 {
font-size: 18px;
margin-bottom: 10px;
}

.footer-section p, .footer-section ul {
font-size: 14px;
line-height: 1.6;
}

.footer-section ul {
list-style: none;
padding: 0;
}

.footer-section ul li {
margin-bottom: 5px;
}

.partner-logos img {
  width: 110px;
  height: 90px;
  padding: 10px;
}

.social-icons a img {
  width: 60px;
  height: 60px;
  padding: 10px;
}

.footer-bottom {
text-align: center;
margin-top: 20px;
border-top: 1px solid #444;
padding-top: 10px;
}

.email-link, .phone-link {
color:white;
text-decoration: none;
}

.email-link:hover, .phone-link:hover {
text-decoration: underline;
}

@media (max-width: 800px) {
  .footer-background {
    height: 35vh;
  }

  .footer-section {
    margin: 5px;
  }

  .footer-section h2 {
    font-size: 16px;
  }

  .footer-section p, .footer-section ul {
    font-size: 12px;
  }

  .partner-logos img {
    width: 90px;
    height: 80px;
    padding: 10px;
  }

  .social-icons a img {
    width: 50px;
    height: 50px;
    padding: 10px;
  }
}