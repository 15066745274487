.reviews-background {
  background-position: center;
  background-size: cover; 
  height: 70vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  opacity: 100%;
}

.reviews-container{
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 15px;
  max-width: 90%;
  width: 600px;
}

.review-slide {
  padding: 10px;
}

.review-image {
  max-width: 100%;
  border-radius: 5px;
}

.review-heading{
  font-weight: bold;
  margin: 20px;
  font-size: 40px;
  text-underline-offset: 8px;
  text-align: center;
  padding-top: 20px;
}
/* Media Queries */
@media (max-width: 1024px) {
  .review-image {
    max-width: 100%;
  }
  .review-page-header {
    font-size: 45px;
  }
  .reviews-background{
    height: 55vh;
  }
  
}