.bathroom-background {
    background-image: url('../../public/bathroom.jpg');
    background-position: center;
    background-size: cover; 
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    text-shadow: 1px 1px 2px black;
    position: relative; 
    opacity: 97%;
    padding-left:30px;
  }
  
  .bathroom-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15); 
    z-index: 1;
    pointer-events: none;
  }

  .showerhead-section,
  .tileitems-section{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    transition: left 0.3s ease; 
  }

  .showerhead-section{
    position: absolute;
    top: 20%; 
    left: 34%; 
  }
  .tileitems-section{
    position: absolute;
    top: 28%; 
    left: 16%; 
  }
  @media (max-width: 800px) {
    .showerhead-section,
    .tileitems-section {
      left: calc(100vw - 200px); 
      margin-left: 20px; 
    }
    .showerhead-section {
      top: 45%;
    }
    .tileitems-section {
      top: 55%;
    }
  
  }